const requestApi = require("@/lib/request");
// 商户接口
module.exports = {
    // 商户列表
    storeList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/store/store-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //商户操作
    storeForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/store/store-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //商户删除
    storeDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/store/store-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}