<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-form ref="searchForm" :model="searchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input size="small" type="text" v-model="searchForm.key" style="width: 180px"
                    placeholder="商户名称/用户名" />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="begin_at">
                  <el-date-picker size="small" style="width: 150px" v-model="searchForm.begin_at" type="date"
                    value-format="yyyy-MM-dd" placeholder="创建开始日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col" style="line-height: 32px"> 至 </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="end_at">
                  <el-date-picker size="small" style="width: 150px" v-model="searchForm.end_at" type="date"
                    value-format="yyyy-MM-dd" placeholder="创建结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button type="primary" size="small" native-type="submit"
                  @click.native.prevent="handleSearch">搜索</el-button>
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetSearchForm">重置</el-button>
              </el-col>
              <el-col class="search_col">
                <el-button type="primary" size="small" @click="doForm(0)">新增商户</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table v-loading="loading" size="small" class="el_table" cell-class-name="el_table_cell"
            header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
            <el-table-column label="头像" width="60">
              <template slot-scope="scope">
                <el-avatar :src="scope.row.face_path" v-if="scope.row.face_path" :size="50"></el-avatar>
                <el-avatar icon="el-icon-user-solid" v-else :size="50"></el-avatar>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商户名称">
            </el-table-column>
            <el-table-column prop="man_name" label="联系人">
            </el-table-column>
            <el-table-column prop="username" label="用户名">
            </el-table-column>
            <el-table-column prop="room_num" label="直播间数量" width="80">
            </el-table-column>
            <el-table-column prop="expire_at" label="到期日期" width="140">
            </el-table-column>
            <el-table-column prop="logined_at" label="最近登录日期" width="140">
            </el-table-column>
            <el-table-column prop="created_at" label="创建日期" width="140">
            </el-table-column>
            <el-table-column fixed="right" align="right" header-align="right" label="操作" width="70">
              <template slot-scope="scope">
                <!-- <el-button
                  class="btn"
                  type="text"
                  icon="el-icon-refresh"
                  size="small"
                  @click="doForm(scope.row.id)"
                >
                  重置密码
                </el-button> -->
                <!-- <el-button class="btn" type="text" icon="el-icon-edit" size="small" @click="doForm(scope.row.id)">
                  编辑
                </el-button> -->
                <el-button @click="deleteItem(scope.row)" class="btn" type="text" icon="el-icon-delete" size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination background layout="total,prev,pager,next,jumper" @current-change="handleCurrentChange"
              :total="pageConfig.counts" :page-size="pageConfig.pageSize" :current-page="pageConfig.pageIndex">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer title="新增商户" size="50%" :visible.sync="drawer" :wrapperClosable="false" :close-on-press-escape="false"
      direction="rtl">
      <div class="drawer_box">
        <el-form ref="modelForm" :rules="modelRules" :model="modelForm" label-width="100px" size="small">
          <el-form-item label="商户头像" size="small" prop="face_path">
            <el-button size="small" style="width: 100px" @click="getResources('face')">+选择图片</el-button>
          </el-form-item>
          <el-form-item label="" size="small" prop="web_face_path" class="no-bottom">
            <div class="more_img_box">
              <div class="img_item one_img_item">
                <el-image class="img" :src="
                  modelForm.web_face_path
                    ? modelForm.web_face_path
                    : '/images/image-empty.png'
                " fit="contain"></el-image>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            图片建议大小：200px*200px
          </el-form-item>
          <el-form-item label="商户名称" size="small" prop="name">
            <el-input v-model="modelForm.name" style="width: 300px" placeholder="商户名称"></el-input>
          </el-form-item>
          <el-form-item label="联系人" size="small" prop="man_name">
            <el-input v-model="modelForm.man_name" placeholder="联系人"></el-input>
          </el-form-item>
          <el-form-item label="用户名" size="small" prop="username">
            <el-input v-model="modelForm.username" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="密码：" size="small" prop="password">
            <el-input v-model="modelForm.password" placeholder="密码" type="password" size="small"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" size="small" prop="com_password">
            <el-input v-model="modelForm.com_password" type="password" placeholder="确认密码" size="small"></el-input>
          </el-form-item>
          <el-form-item label="到期日期" size="small" prop="expire_at">
            <el-date-picker size="small" style="width: 150px" v-model="modelForm.expire_at" type="date"
              value-format="yyyy-MM-dd" placeholder="到期日期">
            </el-date-picker>
          </el-form-item>
          <div class="drawer_footer" style="left: 50%">
            <el-button type="primary" size="small" @click.native.prevent="handleSubmit()"
              :loading="btnLogining">确定</el-button>
            <el-button size="small" @click="drawer = false">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <ResourceWin ref="ResourceWin" v-if="reVisible" :resType="1" :isMore="false" @closeResourceWin="reVisible = false"
      @setResources="setResources" />
  </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import ResourceWin from "@/components/resource/win";
import storeApi from "@/api/store";
export default {
  components: {
    ResourceWin
  },
  data() {
    var valiPassword = (rule, value, callback) => {
      if (value !== this.modelForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      btnLogining: false,
      reVisible: false,
      drawer: false,
      model: null,
      searchForm: {
        key: "",
        begin_at: "",
        end_at: "",
      },
      listData: [],
      tagList: [],
      inputTagVisible: false,
      inputTagValue: "",
      pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      tableHeight: 500,
      modelForm: {
        face_path: "",
        web_face_path: "",
        name: "",
        man_name: "",
        username: "",
        password: "",
        com_password: "",
        expire_at: null,
      },
      modelRules: {
        name: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        man_name: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 4, max: 20, message: "长度为4至20个字符" },
        ],
        com_password: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 4, max: 20, message: "长度为4至20个字符" },
          { validator: valiPassword, trigger: "blur" },
        ],
        expire_at: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    onTableHeight(this);
    this.getList();
  },
  _relType: '',
  methods: {
    //查询
    handleSearch() {
      this.getList(1);
    },
    //重置
    resetSearchForm() {
      if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
      this.getList(1);
    },
    //分页
    handleCurrentChange(val) {
      this.getList(val);
    },
    //列表
    getList(pageIndex = 1) {
      this.loading = true;
      storeApi
        .storeList({
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
          ...this.searchForm,
        })
        .then((res) => {
          this.listData = res.list;
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
        })
        .catch((res) => { })
        .finally(() => {
          this.loading = false;
        });
    },
    getResources(type) {
      this._relType = type;
      this.reVisible = true;
    },
    //设置资源回调方法
    setResources(data) {
      // console.log(data);
      if (this._relType == 'face') {
        this.modelForm.face_path = data.files[0].path;
        this.modelForm.web_face_path = data.files[0].web_path;
      }
      this.reVisible = false;
    },
    //删除
    deleteItem(row) {
      this.$confirm("确定是否删除该商户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          storeApi
            .storeDelete({
              id: row.id,
            })
            .then((res) => {
              message.close();
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getList();
            })
            .catch((res) => {
              message.close();
            });
        })
        .catch(() => { });
    },
    doForm(row) {
      this.model = row;
      if (this.$refs.modelForm) this.$refs.modelForm.resetFields();
      this.drawer = true;
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          storeApi
            .storeForm({
              ...this.modelForm
            })
            .then((res) => {
              this.drawer = false;
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.getList();
            })
            .catch(() => { })
            .finally(() => {
              this.btnLogining = false;
            });
        }
      })
    },
  },
};
</script>